@use '@angular/material' as mat;
@use "@claas/claas-theme-component/src/lib/styles/variables" as var;
@import "/src/styles/variables.scss";

.card, .card.mat-mdc-card {
  &:not(.no-elevation) {
    @include mat.elevation(2);
  }

  background-color: var.$background__paper;
  border-radius: 4px;

  .mat-mdc-card-content {
    &:not(.preserve-padding) {
      padding: 0 24px;

      &:last-child {
        padding-bottom: 24px;
      }

     &:first-child {
        padding-top: 24px;
      }
    }
  }
}
