// DEFINE GLOBAL VARIABLES HERE

$black_01: rgba(0, 0, 0, 0.01); // box-shadow
$black_02: rgba(0, 0, 0, 0.02); // box-shadow
$black_14: rgba(0, 0, 0, 0.14); // box-shadow


// LAYOUT
$grid-spacing: 20px;
$fixed-layout-height: 80px;
$fixed-layout-padding: 20px;
$authed-layout-padding: 40px;

$icon-size-sticky: 35px;
$icon-size: 35px;

// Font defaults
$claas-default-font-family: 'CLAASCultivaWeb', sans-serif;
$claas-light-default-font-weight: 300 !important;
$claas-bold-default-font-weight: 500 !important;

