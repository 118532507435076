@use '@angular/material' as mat;
@use '@claas/claas-theme-component/src/lib/styles/typography';
@use '@claas/claas-theme-component/src/lib/styles/variables' as var;
@import './variables';
// Utils

.mat-typography {
  @include mat.m2-typography-level(typography.$fontConfig, 'body-1');
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .heading-h6,
  .mat-h1,
  .mat-h2,
  .heading-h6,
  .mat-subtitle-2 {
    &.no-margin {
      margin-bottom: 0;
    }
  }
}

.mat-typography {
  h1 {
    @include mat.m2-typography-level(typography.$fontConfig, 'headline-5');
  }
  h2 {
    @include typography.th6-typography();
  }
  h3 {
    @include typography.th6-typography();
  }
  h4 {
    @include mat.m2-typography-level(typography.$fontConfig, 'subtitle-2');
  }
}

.sub-text {
  color: var.$diagram__03;
  font-weight: $claas-light-default-font-weight;
  font-size: 12px;
  margin-top: 5px;
}

.font-weight-light {
  font-weight: $claas-light-default-font-weight;
}

.font-weight-bold {
  font-weight: $claas-bold-default-font-weight;
}

// universal colors for success-warning-error icons
.icon-color-success {
  color: var.$success__light !important;
}

.icon-color-warning {
  color: var.$warning__light !important;
}

.icon-color-error {
  color: var.$error__main !important;
}
.icon-color-info {
  color: var.$inherit__textPrimary !important;
}
.icon-color-success::before {
  color: var.$success__light !important;
}

.icon-color-warning::before {
  color: var.$warning__light !important;
}

.icon-color-error::before {
  color: var.$error__main !important;
}

.icon-color-neutral::before {
  color: var.$secondary__main !important;
}

.bg-color-success::after {
  background-color: var.$success__light;
}

.bg-color-warning::before {
  background-color: var.$warning__light;
}

.bg-color-error::before {
  background-color: var.$error__main;
}

.bg-color-neutral::before {
  background-color: var.$secondary__main;
}

.bg-red {
  // background: var.$error__main;
  background: var.$error__main;
}

.fg-red {
  // color: var.$error__main;
  color: var.$error__main;
}

.bg-claas-green {
  background: var.$primary__main;
}

.fg-claas-green {
  color: var.$primary__main;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute !important;
}
.w80 {
  width: 80%;
}

.wsp {
  white-space: pre !important;
}
.fll {
  float: left;
}
.flr {
  float: right;
}
.tal {
  text-align: left;
}

.pl8 {
  padding-left: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.hideCheckbox {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
}

// typography/subtitle1
.tsub1 {
  font-size: 16px;
  line-height: 28px;
  font-weight: $claas-bold-default-font-weight;
  letter-spacing: 0.15px;
}

//typography/subtitle2
.tsub2 {
  font-size: 14px;
  line-height: 21.98px;
  font-weight: $claas-bold-default-font-weight;
  letter-spacing: 0.1px;
}

.tbody2 {
  @include mat.m2-typography-level(typography.$fontConfig, 'body-2');
}

.tinput-label {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
}

// components/tooltip
//found in lubricants as a warning
.ttooltip {
  font-size: 10px;
  line-height: 14px;
}

.title {
  left: unset !important;
}

.subtitle-1 {
  font-style: normal;
  font-weight: $claas-bold-default-font-weight; // 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: var.$text__primary;
}

@mixin subtitle2-text {
  font-style: normal;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;

  color: var.$text__primary;
}

.subtitle-2 {
  @include subtitle2-text();
}

.tcaption {
  @include mat.m2-typography-level(typography.$fontConfig, 'caption');
}

// typography h5
.th5 {
  font-weight: $claas-bold-default-font-weight;
  font-size: 20px;
  line-height: 32px;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin cde-scrollbar-large {
  // Styling Scrollbars in Firefox
  * {
    scrollbar-width: thin; /* "auto" or "thin"  */
    scrollbar-color: var.$secondary__light #fff; /* scroll thumb and track */
  }
  *::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    height: 8px;
  }
  *::-webkit-scrollbar-track {
    background: #ffffff; /* color of the tracking area */
  }
  *::-webkit-scrollbar-thumb {
    background-color: var.$secondary__light; /*  color of the scroll thumb    */
    border-radius: 4px; /* roundness of the scroll thumb  */
    border: 3px solid var.$secondary__light; /* creates padding around scroll thumb */
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

@mixin cde-scrollbar {
  margin: 4px 4px 0 0;
  *::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px var.$background__default !important;
    border-radius: 4px !important;
    background-color: #ffffff !important;
  }
  &::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    background-color: #ffffff !important;
    border-radius: 2px !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    -webkit-box-shadow: inset 0 0 6px var.$secondary__light !important;
    background-color: var.$secondary__light !important;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  scrollbar-color: var.$secondary__light #fff !important; /* track thumb */
  scrollbar-width: thin !important; /* none, thin, or auto */
}

.cde-modal {
  .cde-scrollbar {
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff;
      border-radius: 4px;
      border: 3px solid #c7c7c7;
      cursor: pointer;
    }
  }
}

.add-product-modal {
  .mat-step-icon {
    width: 24px;
    height: 24px;
  }
  .mat-step-icon-content {
    font-style: normal;
    font-weight: $claas-bold-default-font-weight;
    font-size: 14px;
    line-height: 14px;
  }
}

.tac {
  text-align: center;
}

.mat-app-background {
  background-color: var.$background__paper;
}

.header-wrapper {
  border-bottom: 1px solid var.$secondary__contrast;
  .cc-apps {
    mat-icon {
      vertical-align: middle;

      svg path {
        fill: var.$text__secondary !important;
      }
    }
  }
  .MuiSvgIcon-root {
    color: var.$text__secondary;
  }
}

.ttc {
  text-transform: capitalize;
}
.ttc-fl:first-letter {
  text-transform: capitalize;
}
@mixin pickerInput($fontSize, $fontWeight) {
  font-style: normal;
  font-family: $claas-default-font-family;
  font-size: $fontSize;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var.$text__primary;
}
@mixin pickerInputActive($fontSize, $fontWeight) {
  font-family: $claas-default-font-family;
  font-size: 14px;
  line-height: 20px;
}

.responsive-table .mobile-label,
.responsive-table .mobile-option {
  display: none;
}

.no-activity-data {
  .no-activity-image {
    width: 160px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    img {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .no-activity-desc {
    text-align: left;
    margin-top: 15px;
    margin-left: 21px;
    .no-activity-title {
      display: block;
      margin: 0 0 8px 0;
      font-style: normal;
      font-weight: $claas-bold-default-font-weight;
      font-size: 16px;
      line-height: 28px;
      color: var.$primary__main;
    }
    .no-activity-subtitle {
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0107em;
      color: var.$text__primary;
    }
  }
}

@media only screen and (max-width: 959px) {
  .mat-mdc-table.responsive-table {
    .mobile-label {
      display: inline-block;
      font-weight: $claas-bold-default-font-weight;
    }

    .mobile-option {
      display: inline-block;
      margin-right: 8px;
    }

    td {
      width: 100%;
    }

    .mat-mdc-cell {
      min-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      flex-wrap: wrap;
    }

    .mat-mdc-header-row {
      display: none;
    }

    .mat-mdc-row {
      flex-direction: column;
      align-items: start;
      padding: 8px 24px;
    }

    .mdc-data-table__row {
      .mat-mdc-cell:not(:last-of-type) {
        border-bottom: none;
      }
    }

    .chart-field :last-child {
      width: 100px;
    }
  }
}

a[disabled],
a.disabled {
  color: var.$backdrop__fill !important;
  pointer-events: none !important;
  cursor: text;
}

.heading-h6 {
  @include typography.th6-typography();
}
