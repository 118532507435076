@use "./variables" as var;
@use "./typography" as typography;

// as of now structure of material components
// mat-mdc-form-field (possible extra classes small mat-mdc-form-field-type-mat-input mat-mdc-form-field-has-icon-suffix mat-form-field-appearance-outline mat-primary mat-form-field-hide-placeholder)
//    mat-mdc-text-field-wrapper mdc-text-field mdc-text-field--outlined
//        mat-mdc-form-field-flex
//            mdc-notched-outline mdc-notched-outline--upgraded                       ----> Outline with label
//                mat-mdc-notch-piece mdc-notched-outline__leading
//                mat-mdc-notch-piece mdc-notched-outline__notch
//                    mdc-floating-label mat-mdc-floating-label
//                mat-mdc-notch-piece mdc-notched-outline__trailing
//            mat-mdc-form-field-infix                                                ----> Field
//                actual form component (input, select ...)
//            mat-mdc-form-field-icon-suffix                                          ----> Suffix
//    mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align            ----> Hints, errors
//        mat-mdc-form-field-hint-wrapper
//            mat-mdc-form-field-hint-spacer
.mat-mdc-form-field {
  background-color: inherit;

  &.mat-mdc-form-field-has-icon-suffix {
    .mat-mdc-text-field-wrapper {
      padding: 0 0.75rem;
    }
  }

  .mat-mdc-text-field-wrapper {
    background-color: var.$background__paper;
    border-radius: var(--mdc-shape-small, 4px);
    padding: 0 0.75rem;
    @include typography.input-text-small-typography();
    line-height: 24px;

    .mat-mdc-input-element, .mat-mdc-select,  {
      @include typography.input-text-small-typography();
      line-height: 24px;
    }

    .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 25px;
      @include typography.input-text-small-typography();
    }
  }

  &.inheritedBackground .mat-mdc-text-field-wrapper {
    background-color: inherit;
  }

  .mdc-text-field--outlined .mdc-floating-label:not(.mdc-floating-label--float-above){
    left: 0;
  }

  .mat-mdc-form-field-hint-wrapper{
    padding: 0 16px 0 12px;
  }

  .mat-mdc-form-field-subscript-wrapper:not(.mat-mdc-form-field-subscript-dynamic-size) {
    height: 1.25rem;
  }

  .mat-mdc-form-field-hint {
    @include typography.helper-text-typography();
    color: var.$text__secondary;
    display: flex;
    align-items: flex-end;
    height: 1.1875rem;
  }

  &.readOnly {
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) {
      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-color: var.$input-outlined__disabledBorder;
      }
    }

    cursor: not-allowed;

    mat-select {
      pointer-events: none;
    }
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: var.$input-outlined__disabledBorder;
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing{
      border-color: var.$input-outlined__enabledBorder;
    }
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
  .mdc-notched-outline {
    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
      border-color: var.$input-outlined__hoverBorder;
    }
  }

  .mdc-text-field--outlined.mdc-text-field--disabled {
    .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing{
      border-color: var.$input-outlined__disabledBorder;
    }
  }

  .mdc-text-field--outlined.mdc-text-field--focused:not(.mdc-text-field--invalid) mat-label {
    color: var.$primary__dark;
  }
  .mat-form-field-disabled mat-label {
    color: var.$text__disabled;
  }

  .mdc-floating-label--float-above {
    mat-label {
      @include typography.input-label-typography();
      --mat-mdc-form-field-label-transform: translateY(-34.75px);
    }
  }
  mat-label {
    @include typography.input-text-small-typography();
  }

  .mdc-text-field:not(.mdc-text-field--invalid) mat-label {
    color: var.$text__secondary;
  }

  &.error {
    .error {
    // For subcomponents with error class - important
      color: var.$error__dark;
    }

    color: var.$error__dark;

    .mat-icon svg {
      opacity: 1;
    }

    .mdc-floating-label.mat-mdc-floating-label{
      color: var.$error__dark;
    }

    mat-label{
      color: var.$error__dark;
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper{
      padding: 0;
    }

    &.hasValue mat-label,
    &.mat-focused mat-label {
      color: var.$error__dark;
    }

    .mat-hint {
      color: var.$error__dark;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing,
    .mdc-text-field--outlined .mdc-notched-outline > *,
    .mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline > * {
      border-color: var.$error__main;
      border-width: 2px;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
    .mdc-notched-outline {
      .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
        border-color: var.$error__main;
      }
    }

    .mat-mdc-select-arrow svg {
      fill: var.$action__active;
    }

    .mdc-floating-label--required {
      color: var.$error__main;
    }

  }

  .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: center;
  }

  .text-right {
    text-align: right;
  }

  &.small {

    .mdc-floating-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above){
      top:18px;
    }

    .mat-mdc-text-field-wrapper {
      height: 2.5rem;
    }

    .mdc-notched-outline {
      height: 2.5rem;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
      height: 40px;
      min-height: 40px;
    }
  }
}