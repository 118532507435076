@use "@angular/material" as mat;
@use "./variables" as var;
@use "./typography" as typography;

.stretch {
  width: 100%;
}

.display {
  display: block;
}

.padding-left-4 {
  padding-left: 0.25rem;
}

.mat-progress-spinner.mat-spinner {
  margin: 0 auto;
}

.flex-justify-center {
  flex-direction: row;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}

button {
  height: 2.25rem;
  white-space: nowrap;

  .mat-mdc-progress-spinner {
    // spinnet don't take variables color
    --mdc-circular-progress-active-indicator-color: var.$neutral__focusVisible;
  }

  &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label div),
  &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label span){
    padding: 0 16px 0 10px;
  }

  &.small {
    height: 1.875rem;
    @include typography.button-small-typography();

    &.mdc-button {
      padding: 0 12px;

      &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label div),
      &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label span){
        padding: 0 12px 0 10px;
      }
    }

    &.onlyIcon {
      min-width: 1.875rem;
      width: 1.875rem;
      padding: 0;
    }
  }

  &.large {
    height: 2.625rem;
    @include typography.button-large-typography();

    &.mdc-button {
      padding: 0 24px;

      &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label div),
      &.mdc-button:has(.mdc-button__label lib-icon):has(.mdc-button__label span){
        padding: 0 24px 0 16px;
      }
    }
  }

  &.onlyIcon {
    min-width: 2.25rem;
    width: 2.25rem;
    padding: 0 0.375rem;
  }
  &.mat-mdc-raised-button,
  &.mat-mdc-raised-button:not(:disabled):active,
  &.mat-mdc-raised-button.mdc-ripple-upgraded--background-focused,
  &.mat-mdc-raised-button:not(.mdc-ripple-upgraded):focus {
    box-shadow: none;
  }

  &.mat-mdc-raised-button:disabled,
  &.mat-mdc-outlined-button:disabled,
  &.mat-mdc-unelevated-button:disabled {
    color: var.$text__disabled;
  }

  // filled
  &.mat-mdc-raised-button:not(&.critical):not(:disabled):active.mat-primary {
    --mat-mdc-button-persistent-ripple-color: var.$inherit__white-main;
    --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.3);
    background-color: var.$primary__main;
  }

  &.mat-mdc-raised-button:not(:disabled):hover:not(&.critical) {
    background-color: var.$primary__inherit;
  }

  &.mat-mdc-raised-button:disabled {
    background-color: var.$action__disabledBackground;
  }

  //critical
  &.critical {
    lib-icon {
      color: var.$error__contrast;
    }

    &.mat-mdc-raised-button:not(:disabled):active {
      --mat-mdc-button-persistent-ripple-color: var.$error__contrast;
      --mat-mdc-button-ripple-color: var.$inherit__white-focusVisible;
    }

    &.mat-mdc-raised-button:not(:disabled):hover {
      background: var.$error__dark;
    }
  }

  // outlined
  &.mat-mdc-outlined-button:not(:disabled):active {
    --mat-mdc-button-persistent-ripple-color: var.$secondary__main;
    --mat-mdc-button-ripple-color: var.$secondary__focusVisible;
  }

  &.mat-mdc-outlined-button {
    color: var.$secondary__main;
  }
  &.mat-mdc-outlined-button {
    border-color: var.$secondary__outlinedBorder;
  }

  &.mat-mdc-outlined-button:not(:disabled):hover {
    background-color: var.$secondary__hover;
  }

  &.mat-mdc-outlined-button:disabled {
    border-color: var.$action__disabledBackground;
  }

  // outlined colored
  &.outlinedColored {
    &.mat-mdc-outlined-button {
      border-color: var.$primary__main;
      border-width: 2px;
    }

    &.mat-mdc-outlined-button:not(:disabled):hover {
      background-color: var.$primary__hover;
    }

    &.mat-mdc-outlined-button:not(:disabled):active {
      background-color: var.$primary__focusVisible;
    }

    &.mat-mdc-outlined-button:disabled {
      border-color: var.$action__disabledBackground;
    }
  }

  //text
  &.textButton {

    &:not(:disabled) {
      color: var.$primary__dark;
    }

    text-decoration: underline;
    text-underline-offset: 3px;
    background-color: inherit;

    lib-icon {
      color: var.$primary__dark;
    }

    &.small {
      text-underline-offset: 2px;
    }

    &.mat-mdc-outlined-button:not(:disabled):active {
      --mat-mdc-button-persistent-ripple-color: var.$primary__main;
      --mat-mdc-button-ripple-color: var.$primary__focusVisible;
    }

    &.mat-mdc-unelevated-button:not(:disabled):hover {
      background-color: var.$primary__hover;
    }

    &.mat-mdc-unelevated-button:not(:disabled):active {
      background-color: var.$primary__focusVisible;
    }

    &.mat-mdc-unelevated-button:disabled,
    &.isLoading:not(:disabled) {
      color: var.$action__disabled;
      background-color: inherit;
    }
  }

  &.textColored {
    color: var.$secondary__main;
    text-decoration: underline;
    text-underline-offset: 3px;
    background-color: inherit;

    &.small {
      text-underline-offset: 2px;
    }

    &.mat-mdc-unelevated-button:not(:disabled):active {
      --mat-mdc-button-persistent-ripple-color: var.$primary__main;
      --mat-mdc-button-ripple-color: var.$secondary__focusVisible;
    }

    &.mat-mdc-unelevated-button:not(:disabled):hover {
      background-color: var.$secondary__hover;
    }

    &.mat-mdc-unelevated-button:disabled,
    &.isLoading {
      color: var.$action__disabled;
      background-color: inherit;
    }
  }

  // primaryOutline
  &.primaryOutline {
    color: var.$primary__contrast;
    border-width: 2px;

    &.mat-mdc-outlined-button:not(:disabled):active {
      --mat-mdc-button-persistent-ripple-color: var.$primary__main;
      --mat-mdc-button-ripple-color: var.$primary__focusVisible;
    }

    &.mat-mdc-outlined-button {
      border-color: var.$primary__main;
    }

    &.mat-mdc-outlined-button:not(:disabled):hover {
      background-color: var.$primary__hover;
    }

    &.mat-mdc-outlined-button:disabled {
      border-color: var.$action__disabledBackground;
    }
  }

  // ICON button -- generic material styles
  .mdc-icon-button.mat-mdc-icon-button {
    display: flex;
    align-items: center;
  }

  .mdc-icon-button.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: var.$secondary__hover;
  }

  .mdc-icon-button.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
    opacity: 1;
    background-color: var.$secondary__selected;
  }

  // ICON button -- CLAAS components styles
  &.smallFont.mat-mdc-icon-button.mat-mdc-button-base {
    //width: 2.75rem;
    //height: 2.75rem;
    width: 2.5rem;
    height: 2.5rem;
    padding: 10px;

    .mat-mdc-button-touch-target {
      width: 2.75rem;
      height: 2.75rem;
    }
  }

  &.smallIconButton.mat-mdc-icon-button.mat-mdc-button-base {
    width: 1.75rem;
    height: 1.75rem;
    padding: 2px;

    .mat-mdc-button-touch-target {
      width: 1.75rem;
      height: 1.75rem;
    }

    &.smallFont {
      width: 1.75rem;
      height: 1.75rem;
      padding: 4px;

      &.mat-mdc-button-touch-target {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  }
}
