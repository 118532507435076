@use "@angular/material/index" as mat;
@use "./variables" as var;
@use "./typography" as typography;

mat-checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):focus ~ .mdc-checkbox__ripple {
  opacity: 0;
}

mat-checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):hover ~ .mdc-checkbox__ripple {
  opacity: 1;
  background-color: var.$primary__hover;
}

.mat-mdc-checkbox {
  &.input-top {
    .mdc-form-field {
      display: flex;
      align-items: flex-start;
    }
  }
}

claas-checkbox {
  mat-checkbox {
    width: 100%;
  }
}

mat-checkbox {
  .mdc-form-field > label {
    padding-left: 0;
  }

  .mdc-form-field {
    display: flex;
  }
  &.truncated label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background {
    border-color: var.$text__disabled;
  }

  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background {
    background-color: var.$text__disabled;
  }

  .mat-ripple-element {
    background-color: var.$primary__focusVisible !important;
  }

  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:hover:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background,
  &.mat-mdc-checkbox
    .mdc-checkbox__native-control:focus:enabled:hover:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background,
  &.mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background {
    border-color: var.$text__secondary;
  }

  &.mat-mdc-checkbox
    .mdc-checkbox__native-control:focus:enabled:not(disabled):not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background,
  &.mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"][disabled] ~ .mdc-checkbox__background {
    border-color: var.$text__secondary;
  }

  &.secondaryClass {
    .mat-ripple-element {
      background-color: var.$secondary__focusVisible !important;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):hover ~ .mdc-checkbox__ripple {
      opacity: 1;
      background-color: var.$secondary__hover;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: white;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      background-color: var.$secondary__main;
      border-color: var.$secondary__main;
    }

    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border-color: var.$text__secondary;
    }

    &.mat-mdc-checkbox
      .mdc-checkbox__native-control:focus:enabled:hover:not(:active):not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:hover:not(:active):not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border-color: var.$text__secondary;
    }
  }

  &.errorClass {
    .mat-ripple-element {
      background-color: var.$error__focusVisible !important;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:not([disabled]):hover ~ .mdc-checkbox__ripple {
      opacity: 1;
      background-color: var.$error__hover;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: white;
    }

    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      background-color: var.$error__main;
      border-color: var.$error__main;
    }

    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border-color: var.$error__main
    }

    &.mat-mdc-checkbox
      .mdc-checkbox__native-control:focus:enabled:hover:not(:active):not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background,
    &.mat-mdc-checkbox
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:hover:not(:active):not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
        border-color: var.$error__main
    }
  }
}
