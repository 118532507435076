@use "@angular/material" as mat;
@use "./variables" as var;
@use "./typography" as typography;

claas-toggle {
  mat-slide-toggle {
    width: 100%;

    .mdc-form-field {
      display: flex;
    }
  }
}

mat-slide-toggle {
  .mdc-switch{
    --mdc-switch-selected-pressed-state-layer-opacity: 1;
  }

  &.truncated label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.input-top {
    .mdc-form-field {
      display: flex;
      align-items: flex-start;
    }
  }

  .mdc-form-field > label {
    padding-left: 0.5rem;
  }

  .mdc-switch__icon.mdc-switch__icon--off {
    display: none;
  }

  &.mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__shadow {
    box-shadow: var(
                    --mdc-switch-handle-elevation,
                    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.12)
    );
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
    fill: var.$primary__contrast;
  }

  &.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:hover .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:focus:hover:not(:active) .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    background: var.$primary__main;
    opacity: 0.5;
  }

  &.mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:hover .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:focus:not(:active) .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:focus:hover:not(:active) .mdc-switch__track::after,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    background: var.$action__disabledBackground;
  }

  &.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track:before,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:hover .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:focus:hover:not(:active) .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
    background: var.$switch__slideFill;
    opacity: 0.38;
  }

  &.mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__track {
    opacity: 1;
  }

  &.mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__track:before,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:hover .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:focus:not(:active) .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:focus:hover:not(:active) .mdc-switch__track::before,
  &.mat-mdc-slide-toggle .mdc-switch:disabled:hover:not(:focus):not(:active) .mdc-switch__track::before {
    background: var.$action__disabledBackground;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus:hover:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
    background: var.$primary__main;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
    fill: var.$action__disabled;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
    fill: var.$switch__knobFill;
  }

  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
    background: var.$switch__knobFill;
  }
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: var.$switch__knobFill;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:hover .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:focus:hover:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
    background: var.$switch__knobFill;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled:hover .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled:focus:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled:focus:hover:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled:hover:not(:focus):not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled:hover .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled:focus:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled:focus:hover:not(:active) .mdc-switch__handle::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
    background: var.$switch__knobDisabledFill;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
    fill: var.$switch__knobDisabledFill;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--unselected:focus .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--unselected:focus .mdc-switch__ripple::after,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--unselected:hover .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--unselected:hover .mdc-switch__ripple::after{
    background-color: var.$inherit__white-focusVisible;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--selected:focus .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--selected:focus .mdc-switch__ripple::after,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--selected:hover .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:has(label):has(:active) .mdc-switch.mdc-switch--selected:hover .mdc-switch__ripple::after{
    background-color: var.$primary__focusVisible;
    opacity: 1;
  }

  // ripple
  &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
    height: 2.375rem;
    width: 2.375rem;
  }

  &.mat-mdc-slide-toggle:not(:has(label):has(:active)) .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:not(:has(label):has(:active)) .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
    background-color: var.$action__hover;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle:not(:has(label):has(:active)) .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle:not(:has(label):has(:active)) .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__ripple::after {
    background-color: var.$primary__hover;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
    background-color: var.$primary__focusVisible;
    opacity: 1;
  }

  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::before,
  &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled:focus .mdc-switch__ripple::after {
    background-color: var.$inherit__white-focusVisible;
    opacity: 1;
  }

  &.small {
    .mdc-form-field > label {
      padding-left: 0;
    }
    &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
      width: 1rem;
      height: 1rem;
    }
    &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
      width: 26px;
      height: 10px;
    }
    &.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
      width: 12px;
      height: 12px;
    }
    &.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
