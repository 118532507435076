@use "@angular/material" as mat;
@use "./variables" as var;
@use "./typography" as typography;

input.mat-input-element {
  color: var.$text__primary;
  @include mat.m2-typography-level(typography.$fontConfig, "body-2");

  &::placeholder {
    color: var.$text__secondary;
  }
}

.mat-mdc-form-field {
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    @include typography.input-text-small-typography();
  }

  .mat-mdc-form-field-type-mat-input.mat-form-field-appearance-outline .mdc-text-field--focused {
    .suffix-icon-button {
      margin-right: 0.25rem;
    }
  }

  &.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    color: var.$text__disabled;
  }
}
