@use "@angular/material" as mat;
@use "./variables" as var;
@use "./typography" as typography;

claas-radio-button {
  mat-radio-button {
    .mdc-form-field {
      display: flex;
    }
  }
}

mat-radio-button {
  &.mat-mdc-radio-button.mat-primary,
  &.mat-mdc-radio-button.mat-warn,
  &.mat-mdc-radio-button.mat-accent {
    --mat-radio-ripple-color: none;
  }

  &.truncated label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.mat-mdc-radio-button.mat-warn {
    --mat-mdc-radio-ripple-color: var.$error__hover;
  }
  &.mat-mdc-radio-button.mat-primary {
    --mat-mdc-radio-ripple-color: var.$primary__hover;
  }

  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    width: 38px;
    height: 38px;
  }

  &.input-top {
    .mdc-form-field {
      display: flex;
      align-items: flex-start;
    }
  }

  .mdc-form-field > label {
    padding-left: 0;
  }
  &.mat-mdc-radio-button .mdc-radio {
    padding: 9px;
  }
  .mat-mdc-radio-touch-target {
    width: 38px;
    height: 38px;
  }

  &.mat-mdc-radio-button
  .mdc-radio
  [aria-disabled="true"]
  .mdc-radio__native-control:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  *.mat-mdc-radio-button
  .mdc-radio
  [aria-disabled="true"]
  .mdc-radio__native-control:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var.$text__disabled;
    opacity: 1;
  }

  &.mat-mdc-radio-button
  .mdc-radio
  [aria-disabled="true"]
  .mdc-radio__native-control:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var.$text__disabled;
  }

  &.mat-mdc-radio-button .mdc-radio [aria-disabled="true"] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: var.$text__disabled;
    opacity: 1;
  }

  &.mat-primary.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
    border-color: var.$text__secondary;
  }

  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:hover:checked + .mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:focus:checked + .mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button
  .mdc-radio:not(:disabled):active
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__inner-circle {
    opacity: 1;
    border-color: var.$text__primary;
  }

  &.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element,
  .mat-ripple-element {
    background-color: var.$primary__focusVisible;
  }

  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:not([disabled]):hover ~ .mat-radio-ripple {
    opacity: 1;
    background-color: var.$primary__hover;
  }

  &.secondaryClass {
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var.$text__secondary;
    }

    .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var.$text__secondary;
    }

    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:hover:checked + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:focus:checked + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button
    .mdc-radio:not(:disabled):active
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
      border-color: var.$secondary__main;
    }

    &.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element,
    .mat-ripple-element {
      background-color: var.$secondary__focusVisible;
    }

    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:not([disabled]):hover ~ .mat-radio-ripple {
      opacity: 1;
      background-color: var.$secondary__hover;
    }
  }

  &.mat-mdc-radio-button .mat-radio-ripple .mat-ripple-element,
  .mat-ripple-element {
    opacity: 1;
  }

  &.errorClass {
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var.$text__secondary;
    }

    .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: var.$text__secondary;
    }

    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:hover:checked + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:focus:checked + .mdc-radio__background .mdc-radio__inner-circle,
    &.mat-mdc-radio-button
    .mdc-radio:not(:disabled):active
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
      border-color: var.$error__main;
    }

    &.mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element,
    .mat-ripple-element,
    &mat-radio-button.errorClass .mat-ripple-element {
      background-color: var.$error__focusVisible;
    }

    &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:not([disabled]):hover ~ .mat-radio-ripple {
      opacity: 1;
      background-color: var.$error__hover;
    }
  }
}
