// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $cde-fe-app-primary: mat.define-palette(mat.$indigo-palette);
// $cde-fe-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $cde-fe-app-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $cde-fe-app-theme: mat.define-light-theme((
//   color: (
//     primary: $cde-fe-app-primary,
//     accent: $cde-fe-app-accent,
//     warn: $cde-fe-app-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include mat.all-component-themes($cde-fe-app-theme);

@use '@angular/material' as mat;
@use '@claas/claas-theme-component/src/lib/styles/variables' as variables;
@use '@claas/claas-theme-component/src/styles';

@import './styles/variables';

html,
body {
  height: 100%;
  width: 100%;
  position: relative;

  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);

  --mdc-filled-button-label-text-size: 0.875rem;
  --mat-table-header-headline-size: 0.875rem;
  --mdc-dialog-supporting-text-size: 1.25rem;

  --mat-sys-primary: #b3c618;

  --mdc-checkbox-selected-icon-color: var(--mat-sys-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--mat-sys-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--mat-sys-primary);
  --mdc-checkbox-selected-checkmark-color: variables.$primary__contrast;
  --mdc-checkbox-selected-focus-state-layer-color: var(--mat-sys-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--mat-sys-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--mat-sys-primary);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--mat-sys-primary);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--mat-sys-primary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--mat-sys-primary);

  --mat-full-pseudo-checkbox-selected-icon-color: var(--mat-sys-primary);
  --mat-full-pseudo-checkbox-selected-hover-icon-color: var(--mat-sys-primary);
  --mat-full-pseudo-checkbox-selected-focus-icon-color: var(--mat-sys-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: variables.$primary__contrast;

  --mdc-switch-selected-handle-color: #a5b412;
  --mdc-switch-selected-focus-handle-color: #a5b412;
  --mdc-switch-selected-hover-handle-color: #a5b412;
  --mdc-switch-selected-pressed-handle-color: #a5b412;
  --mdc-switch-selected-track-color: #c3d260;
  --mdc-switch-selected-focus-track-color: #c3d260;
  --mdc-switch-selected-hover-track-color: #c3d260;
  --mdc-switch-selected-pressed-track-color: #c3d260;
  --mdc-switch-selected-icon-color: variables.$primary__contrast;

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-icon-color: var(--mat-sys-primary);
    --mdc-radio-selected-focus-icon-color: var(--mat-sys-primary);
    --mdc-radio-selected-pressed-icon-color: var(--mat-sys-primary);
    --mdc-radio-selected-hover-icon-color: var(--mat-sys-primary);
    --mdc-radio-unselected-hover-icon-color: var(--mat-sys-primary);
    --mdc-radio-selected-pressed-icon-color: var(--mat-sys-primary);
    --mdc-radio-unselected-pressed-icon-color: var(--mat-sys-primary);
  }

  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-hover-outline-color: variables.$primary__contrast;

  --mdc-outlined-text-field-disabled-outline-color: #E0E0E0;

  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: variables.$primary__contrast;

  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
}

body {
  margin: 0;
  position: relative;
  font-family: $claas-default-font-family;
}

footer {
  // height: 44px;
  padding-top: 8px;
  text-align: right;
}

@import './styles/base';
@import './styles/component-selector-styles';
@import './styles/material-overrides';
@import './styles/button-styles';
@import './styles/filter-button-styles';
@import './styles/datepicker';
@import './styles/forms';
@import './styles/toast';
// this can be removed when the validation dialog styling is fixed within the
// registration dialog
@import './styles/address-validation-dialog';
@import './styles/card';

@import './styles/tailwind-output';

@include mat.tooltip-overrides(
  (
    container-color: variables.$snackbar__defaultFill,
  )
);
