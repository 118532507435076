@use "./colors-palettes.scss" as colors;

// Primary
$primary__main: colors.$brand-green__500; // Inherit
$primary__dark: colors.$brand-green__700;
$primary__inherit: colors.$brand-green__600;
$primary__contrast: rgba(colors.$black, 0.87);
$primary__hover: rgba(colors.$brand-green__500, 0.12);
$primary__selected: rgba(colors.$brand-green__500, 0.12);
$primary__focus: rgba(colors.$brand-green__500, 0.12);
$primary__focusVisible: rgba(colors.$brand-green__500, 0.3);
$primary__outlinedBorder: rgba(colors.$brand-green__500, 0.5);

// Secondary
$secondary__main: colors.$silver__700;
$secondary__dark: colors.$silver__800;
$secondary__light: colors.$silver__400;
$secondary__contrast: colors.$grey__300;
$secondary__hover: rgba(colors.$silver__700, 0.08);
$secondary__selected: rgba(colors.$silver__700, 0.08);
$secondary__focus: rgba(colors.$silver__700, 0.12);
$secondary__focusVisible: rgba(colors.$silver__700, 0.3);
$secondary__outlinedBorder: rgba(colors.$silver__700, 0.5);

// Action
$action__active: rgba(colors.$black, 0.5);
$action__hover: rgba(colors.$black, 0.06);
$action__selected: rgba(colors.$black, 0.08);
$action__disabledBackground: rgba(colors.$black, 0.12);
$action__focus: rgba(colors.$black, 0.12);
$action__disabled: rgba(colors.$black, 0.3);

// Inherit
// $primary__main: colors.brand-green__500;
// $inherit__white: colors.$white;
$inherit__white-main: colors.$white;
$inherit__white-hover: rgba(colors.$white, 0.04);
$inherit__white-selected: rgba(colors.$white, 0.08);
$inherit__white-focus: rgba(colors.$white, 0.12);
$inherit__white-focusVisible: rgba(colors.$white, 0.3);
$inherit__disabled: rgba(colors.$white, 0.3);
$inherit__textPrimary: colors.$black;

// Error
$error__main: colors.$red__500;
$error__dark: colors.$red__700;
$error__light: colors.$red__300;
$error__contrast: colors.$white;
$error__hover: rgba(colors.$red__500, 0.08);
$error__selected: rgba(colors.$red__500, 0.08);
$error__focusVisible: rgba(colors.$red__500, 0.3);
$error__outlinedBorder: rgba(colors.$red__500, 0.5);
$error__errorContent: colors.$red__900;
$error__errorFill: colors.$red__100;
$error__errorBageFill: colors.$red__200;

// Warning
$warning__main: colors.$orange__500;
$warning__dark: colors.$orange__700;
$warning__light: colors.$orange__300;
$warning__contrast: colors.$white;
$warning__hover: rgba(colors.$orange__500, 0.04);
$warning__selected: rgba(colors.$orange__500, 0.04);
$warning__focusVisible: rgba(colors.$orange__500, 0.3);
$warning__outlinedBorder: rgba(colors.$orange__500, 0.5);
$warning__warningContent: colors.$orange__900;
$warning__warningFill: colors.$orange__100;
$warning__warningBageFill: colors.$orange__200;

// Success
$success__main: colors.$green__500;
$success__dark: colors.$green__700;
$success__light: colors.$green__300;
$success__contrast: colors.$white;
$success__hover: rgba(colors.$green__500, 0.04);
$success__selected: rgba(colors.$green__500, 0.08);
$success__focusVisible: rgba(colors.$green__500, 0.3);
$success__outlinedBorder: rgba(colors.$green__500, 0.5);
$success__successContent: colors.$green__900;
$success__successFill: colors.$green__100;
$success__successBageFill: colors.$green__200;

// Info
$info__main: colors.$blue__500;
$info__dark: colors.$blue__700;
$info__light: colors.$blue__300;
$info__contrast: colors.$white;
$info__hover: rgba(colors.$blue__500, 0.04);
$info__selected: rgba(colors.$blue__500, 0.08);
$info__focusVisible: rgba(colors.$blue__500, 0.3);
$info__outlinedBorder: rgba(colors.$blue__500, 0.5);
$info__infoContent: colors.$blue__900;
$info__infoFill: colors.$blue__100;
$info__infoBageFill: colors.$blue__200;

// Neutral
$neutral__main: colors.$grey__600;
$neutral__dark: colors.$grey__700;
$neutral__contrast: colors.$white;
$neutral__hover: rgba(colors.$black, 0.08);
$neutral__selected: rgba(colors.$black, 0.08);
$neutral__focusVisible: rgba(colors.$black, 0.3);
$neutral__outlinedBorder: rgba(colors.$black, 0.5);
$neutral__neutralContent: colors.$grey__800;
$neutral__neutralFill: colors.$grey__200;
$neutral__neutralBageFill: colors.$grey__300;

// Text
$text__primary: rgba(colors.$black, 0.87);
$text__secondary: rgba(colors.$black, 0.64);
$text__disabled: rgba(colors.$black, 0.3);
$text__inherit: rgba(colors.$black, 0.87);

// Background
$background__default: colors.$grey__100;
$background__paper: colors.$white;

// Further Colors
$appbar__defailtFill: colors.$grey__100;
$avatar__fill: colors.$grey__200;
$stepper__fill: colors.$grey__300;
$backdrop__fill: rgba(colors.$black, 0.23);
$breadcrumbs__collapseFill: colors.$grey__100;
$divider__divider: colors.$grey__300;
$snackbar__defaultFill: colors.$grey__800;
$tooltip__fill: colors.$grey__800;

// Chip
$chip__defaultCloseFill: colors.$grey__500;
$chip__defaultHoverCloseFill: colors.$grey__700;
$chip__defaultDisabledCloseFill: colors.$grey__300;
$chip__defaultFill: rgba(colors.$black, 0.08);
$chip__defaultHoverFill: rgba(colors.$black, 0.12);
$chip__defaultFocusFill: rgba(colors.$black, 0.23);
$chip__defaultEnabledBorder: colors.$grey__500;
$chip__defaultActiveBorder: colors.$grey__700;

// Input outlined
$input-outlined__enabledBorder: colors.$grey__500;
$input-outlined__disabledBorder: colors.$grey__300;
$input-outlined__hoverBorder: colors.$black;

// Switch
$switch__knobFill: colors.$grey__50;
$switch__knobDisabledFill: colors.$grey__100;
$switch__slideFill: colors.$black;

// Toggle
$toggle_defaultBorder: colors.$grey__500;

// Fruits
$fruits__fruit01: colors.$blueberry__800;
$fruits__fruit02: colors.$blueberry__500;
$fruits__fruit03: colors.$petrol__600;
$fruits__fruit04: colors.$petrol__200;
$fruits__fruit05: colors.$petrol__900;
$fruits__fruit06: colors.$rust-red__800;
$fruits__fruit07: colors.$tan__100;
$fruits__fruit08: colors.$yellow__200;
$fruits__fruit09: colors.$yellow__300;
$fruits__fruit10: colors.$orange-yellow__700;
$fruits__fruit11: colors.$purple-magenta__600;
$fruits__fruit12: colors.$purple-magenta__100;
$fruits__fruit13: colors.$rose__200;
$fruits__fruit14: colors.$rose__600;
$fruits__fruit15: colors.$purple-magenta__800;
$fruits__fruit16: colors.$sea-green__400;
$fruits__fruit17: colors.$brown__200;
$fruits__fruit18: colors.$muddy-green__300;
$fruits__fruit19: colors.$brown__400;
$fruits__fruit20: colors.$muddy-green__900;
$fruits__fruit21: colors.$brown__900;
$fruits__fruit22: colors.$brown__500;
$fruits__fruit23: colors.$tan__200;
$fruits__fruit24: colors.$tan__600;
$fruits__fruit25: colors.$tan__300;

// Maps
$legend-default__01: colors.$yellow__100;
$legend-default__02: colors.$muddy-green__200;
$legend-default__03: colors.$muddy-green__200;
$legend-default__04: colors.$sea-green__200;
$legend-default__05: colors.$sea-green__400;
$legend-default__06: colors.$sea-green__600;
$legend-default__07: colors.$sea-green__800;

$legend-soil__01: colors.$yellow__100;
$legend-soil__02: colors.$orange-yellow__200;
$legend-soil__03: colors.$orange-yellow__300;
$legend-soil__04: colors.$orange-yellow__500;
$legend-soil__05: colors.$rust-red__500;
$legend-soil__06: colors.$rust-red__700;
$legend-soil__07: colors.$rust-red__800;

// Drive Track
$driveTrack__track01: colors.$mint__500;
$driveTrack__track02: colors.$mint__300;
$driveTrack__track03: colors.$blueberry__300;
$driveTrack__track04: colors.$muddy-green__500;
$driveTrack__track05: colors.$petrol__300;
$driveTrack__track06: colors.$rose__100;
$driveTrack__track07: colors.$yellow__400;
$driveTrack__track08: colors.$rust-red__300;
$driveTrack__track09: colors.$purple-magenta__400;
$driveTrack__track10: colors.$purple-magenta__500;

// Field Boundary
$fieldBoundary-polygon__border: colors.$white;
$fieldBoundary-polygon__filling: colors.$white;
$fieldBoundary-polygon__editBorder: colors.$muddy-green__500;
$fieldBoundary-polygon__editFilling: colors.$muddy-green__500;
$fieldBoundary-polygon__errorBorder: colors.$red__500;
$fieldBoundary-polygon__errorFilling: rgba(colors.$red__500, 0.04);
$fieldBoundary-polygon__warningBorder: colors.$orange__250;
$fieldBoundary-polygon__warningFilling: rgba(colors.$orange__500, 0.3);
$fieldBoundary-polygon__successBorder: colors.$green__250;
$fieldBoundary-polygon__successFilling: rgba(colors.$orange__500, 0.3);

// Diagram
$diagram__01: colors.$brand-green__500;
$diagram__02: colors.$olive__500;
$diagram__03: colors.$warm-grey__300;
$diagram__04: colors.$warm-grey__400;
$diagram__05: colors.$gold__700;
$diagram__06: colors.$gold__800;
$diagram__07: colors.$dark-red__300;
$diagram__08: colors.$dark-salmon__800;
$diagram__09: colors.$cool-blue__500;
$diagram__10: colors.$cool-blue__800;

// Claas Signal Color
$claasSignalColor__claas-highlight-color: colors.$claas-highlight-color;
$claasSignalColor__claas-signal-red: colors.$claas-signal-red;
$claasSignalColor__claas-signal-orange: colors.$claas-signal-orange;
$claasSignalColor__claas-signal-yellow: colors.$claas-signal-yellow;
$claasSignalColor__claas-signal-green: colors.$claas-signal-green;

// Other Brands
$otherBrands__Claas: colors.$brand-green__500;
$otherBrands__Lexion-NA: colors.$gold__500;
$otherBrands__John-Deere: colors.$forestgreen__800;
$otherBrands__Fendt: colors.$forestgreen__200;
$otherBrands__Deutz-Fahr: colors.$forestgreen__500;
$otherBrands__Case: colors.$dark-red__400;
$otherBrands__MF: colors.$dark-red__700;
$otherBrands__New-Holland: colors.$gold__300;
$otherBrands__Krone: colors.$gold__100;

$legend__machine-data-07: colors.$petrol__200;
$legend__machine-data-06: colors.$petrol__300;
$legend__machine-data-05: colors.$petrol__500;
$legend__machine-data-04: colors.$petrol__550;
$legend__machine-data-03: colors.$petrol__600;
$legend__machine-data-02: colors.$petrol__800;
$legend__machine-data-01: colors.$petrol__900;
