@use "@angular/material" as mat;
@use "@claas/claas-theme-component/src/lib/styles/variables" as var;
@import "/src/styles/variables.scss";

.button {
  // common button
  border: none;
  height: 42px;
  padding: 8px 24px;
  border-radius: 4px !important;
  font-style: normal;
  font-family: $claas-default-font-family;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 24px;
  width: max-content;

  // Enable progress spinner in a button
  svg {
    --mdc-circular-progress-active-indicator-color: #{var.$primary__main};
  }

  &.small {
    padding: 4px 10px;
    height: 30px;
    font-size: 13px;
    line-height: 22px;
  }

  &.medium {
    padding: 6px 16px;
    height: 36px;
    font-size: 14px;
    line-height: 24px;
  }

  &.large {
    height: 56px;
    font-size: 16px;
    line-height: 26px;
  }

  &.with-icon {
    lib-icon {
      margin-right: 8px;
    }
  }

  &.button-contained {
    background: var.$primary__main;
    color: var.$primary__contrast;
    &:hover,
    &.hover {
      color: var.$primary__contrast;
      cursor: pointer;
      background: var.$primary__inherit;
    }
  }

  &.button-outline {
    border: 1px solid var.$primary__main;
    color: var.$primary__main;
    background: var.$background__paper;

    &:hover,
    &.hover {
      color: var.$primary__main;
      cursor: pointer;
      background: var.$primary__hover;
    }

    &.button-error {
      border: 1px solid var.$error__main;
      color: var.$error__main;
      background: var.$background__paper;

      &:hover,
      &.hover {
        color: var.$error__main;
        cursor: pointer;
        background: rgb(156, 34, 43, 0.08);
      }
    }

    &.black-label {
      color: var.$text__primary;
      font-weight: $claas-bold-default-font-weight;
    }

    &.uncolored {
      border: 1px solid var.$secondary__outlinedBorder;
      color: var.$text__primary;

      &.light {
        border: 1px solid var.$secondary__outlinedBorder;
        color: var.$secondary__main;
      }
    }

    &.disabled, &:disabled {
      border: 1px solid var.$action__focus;
      color: var.$action__disabled;
      pointer-events: none;
      background: var.$background__paper;
    }

    &.transparent {
      background: transparent;
    }
  }

  &.button-text {
    border: none;
    color: var.$primary__main;
    background: var.$background__paper;

    &:hover {
      background: var.$primary__hover;
      cursor: pointer;
    }

    &.secondary {
      color: var.$secondary__main;
      background-color: transparent;

      &.with-border {
        color: var.$secondary__main;
        border: 1px solid var.$secondary__outlinedBorder;
      }

      &:hover,
      &.hover {
        cursor: pointer;
        color: var.$primary__contrast;
        background-color: var.$action__hover;
      }
    }

    &.link {
      color: var.$secondary__main;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: var.$primary__contrast;
        background-color: var.$action__hover;
      }

      &.dark-primary {
        color: var.$diagram__02;
        border-radius: 4px;

        &:hover {
          background: var.$primary__selected;
          cursor: pointer;
        }
      }
    }
  }

  &.gray {
    background-color: var.$neutral__neutralFill;
    color: var.$neutral__dark;
    &.button-outline {
      border: 1px solid var.$neutral__dark;
    }
    &:hover,
    &.hover {
      cursor: pointer;
      background-color: #ddd;
      color: var.$neutral__dark;
    }
  }

  &.error {
    background-color: var.$error__main;
    color: var.$background__paper;

    &:hover,
    &.hover {
      cursor: pointer;
      background-color: var.$error__dark;
      color: var.$background__paper;
    }
  }

  &.main {
    height: 36px;
    height: 24px;
    padding: 6px 16px;
    box-sizing: content-box;
    font-style: normal;
    font-weight: $claas-bold-default-font-weight;
  }

  &.disabled, &:disabled {
    background-color: var.$action__focus;
    color: var.$action__disabled;

    &:hover {
      cursor: default;
      background-color: var.$action__focus;
      color: var.$action__disabled;
    }
  }

  &.icon {
    background: transparent;
    border-radius: 4px;
    color: var.$secondary__main;

    &:hover {
      background: var.$secondary__selected;
      cursor: pointer;
    }
  }
}

.button-icon {
  border-radius: 50%;
  background-color: transparent;
  border: 0;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:hover {
    background-color: var.$action__hover;
  }

  &.disabled, &:disabled {
    color: var.$backdrop__fill;
    pointer-events: none;
    background: transparent;

    lib-icon .mat-icon {
      color: var.$backdrop__fill;
      pointer-events: none;
      background: transparent;
    }

    &:hover {
      pointer-events: none;
    }
  }
}

.cde-btn {
  width: max-content;
  padding: 6px 16px;
  box-sizing: content-box;
  font-style: normal;
  font-family: $claas-default-font-family;
  font-weight: $claas-bold-default-font-weight;
  font-size: 14px;
  line-height: 24px;
  color: var.$text__primary;
  letter-spacing: 0.4px;
  height: 36px;
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  ::ng-deep .mat-button-wrapper {
    height: 24px;
    display: flex;
    align-items: center;
  }
  .cde-btn-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  &.with-icon {
    .cde-btn-text {
      margin-left: 8px;
    }
  }

  &.white {
    .cde-btn-text {
      color: var.$secondary__main !important;
    }
  }

  &.mat-primary.mat-mdc-unelevated-button {
    &:hover,
    &.hover {
      color: var.$primary__contrast;
      cursor: pointer;
      background: var.$primary__inherit;
    }
  }
}
