@use "@angular/material" as mat;
@use "./variables" as var;
@use "./typography" as typography;

// General styling for material components
.mat-mdc-form-field {
  &.mat-primary mat-option.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var.$text__primary;
  }

  .mat-mdc-select-arrow,
  &.mat-focused .mat-mdc-select .mat-mdc-select-arrow {
    color: var.$text__secondary;
  }

  .mat-mdc-select-disabled .mat-mdc-select-value,
  .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
    color: var.$text__disabled;
  }

  .mat-mdc-select-arrow-wrapper{
    padding: 0 0.4375rem;
  }

  // I believe this does nothing because options are in claas-menu not the select
  //.mdc-list-item__primary-text {
  //  color: var.$text__disabled;
  //}
  //&.textRight.mat-mdc-option {
  //  justify-content: flex-end;
  //
  //  .mdc-list-item__primary-text {
  //    display: contents;
  //  }
  //}
  //
  //.mdc-list-item.mdc-list-item--disabled {
  //  .mat-icon svg {
  //    opacity: 0.3;
  //  }
  //}

  // I believe there are no icons displayed in the select (only in options and error in hint which has different opacity)
  //.mat-icon svg {
  //  opacity: 0.67;
  //}
}

.mdc-menu-surface.mat-mdc-select-panel {
  max-height: 376px;
}

mat-option.mdc-list-item {

  &.mdc-list-item--selected:not(&.mdc-list-item--disabled) {
    background-color: var.$primary__selected;
  }

  &.mdc-list-item--disabled > * {
    color: var.$text__disabled;
  }
  &.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var.$action__hover;
  }
  &.mat-mdc-option:focus:not(.mdc-list-item--disabled) {
    background-color: var.$action__focus;
  }

  &.mat-mdc-option.mat-mdc-option-active {
    background-color: var.$action__focus;
  }

  &.mat-mdc-option.mdc-list-item--disabled {
    opacity: 1;

    .mdc-list-item__primary-text {
      color: var.$text__disabled;
    }
  }

  &.textRight.mat-mdc-option {
    justify-content: flex-end;

    .mdc-list-item__primary-text {
      display: contents;
    }
  }
}

// Special styling for CLAAS components
.claasMenu {
  .mat-mdc-option .mat-icon {
    margin-right: 0;
  }

  &.itemSmall {
    .mat-mdc-option {
      height: 32px;
      min-height: 32px;
    }
  }

  &.itemMedium {
    .mat-mdc-option {
      height: 36px;
      min-height: 36px;
    }
  }

}