// @use "./lib/styles/variables" as var;
@use "./variables" as var;

app-cde-toast {
  &.toast-error {
    background-color: transparent;
  }
}

.toast-top-center {
  height: auto;
  top: 16px !important;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.toast-top-right {
  height: auto;
  top: 16px !important;
  right: 25px !important;
}

.toast-top-left {
  height: auto;
  top: 16px !important;
  left: 73px !important;
}

.toast-bottom-left {
  bottom: 12px;
  left: 73px !important;
}

.cde-toast-title {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: var.$inherit__white-main;

  .cde-toast-title-text {
    text-align: left;
  }
}

.cde-toast-message {
  color: var.$inherit__white-main;
  margin-top: 1px;
  margin-bottom: 0;
}

.cde-toast-button {
  cursor: pointer;
  background-color: transparent;
}

.toast-container {
  width: 400px;
  margin-bottom: 0.5rem;

  .alert-info,
  .alert-error,
  .alert-warning {
    width: 640px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;

    .cde-toast-container {
      height: 50px;
    }
  }

  .snackbar-large,
  .snackbar-success,
  .snackbar-error,
  .snackbar-warning,
  .snackbar-no-icon {
    width: 401px;
    height: auto;
    display: block;
    background-color: transparent;

    .cde-toast-button {
      background-color: var.$snackbar__defaultFill;
      border: 1px solid var.$snackbar__defaultFill;
      color: var.$inherit__white-main !important;

      &:hover {
        //color: $color-background-alt-ef !important;
      }
    }

    .cde-toast-action {
      color: var.$primary__main !important;
    }
  }

  .snackbar-large {
    width: 400px;
    display: inline-block;

    .cde-toast-container {
      align-items: flex-end;
      background-color: var.$snackbar__defaultFill;
      border: 1px solid var.$snackbar__defaultFill;
      box-shadow: none;
      padding: 14px 28px 14px 18px;

      .col-10 {
        width: 92%;
      }

      .col-2 {
        width: 8.4%;
      }

      .cde-toast-details {
        align-items: flex-end !important;
      }

      .cde-toast-action {
        display: block;
        color: var.$primary__main !important;
      }
    }

    .cde-toast-button {
      color: var.$inherit__white-main;
    }
  }

  .snackbar-success {
    .cde-toast-container {
      background-color: var.$snackbar__defaultFill;
      border: 1px solid var.$snackbar__defaultFill;
      box-shadow: none;
    }
  }

  .snackbar-error {
    .cde-toast-container {
      background-color: var.$snackbar__defaultFill;
      border: 1px solid var.$snackbar__defaultFill;
      box-shadow: none;
    }
  }

  .snackbar-warning {
    .cde-toast-container {
      background-color: var.$snackbar__defaultFill;
      border: 1px solid var.$snackbar__defaultFill;
      box-shadow: none;
    }
  }

  .inline {
    position: relative;
  }
}

.alert-warning {
  .cde-inline-toast-container,
  .cde-toast-container {
    background-color: var.$warning__warningFill;
    border: 1px solid var.$warning__warningFill;

    .cde-toast-title {
      color: var.$warning__warningContent;
    }

    .cde-toast-message {
      color: var.$warning__warningContent;
    }

    .cde-toast-action {
      color: var.$warning__warningContent !important;
    }
  }
}

.alert-info {
  .cde-inline-toast-container,
  .cde-toast-container {
    background-color: var.$info__infoFill;
    border: 1px solid var.$info__infoFill;

    .cde-toast-title {
      color: var.$error__errorContent;
    }

    .cde-toast-message {
      color: var.$error__errorContent;
    }

    .cde-toast-action {
      color: var.$error__errorContent !important;
    }
  }
}

.alert-error {
  .cde-inline-toast-container,
  .cde-toast-container {
    //background-color: var.$error__errorFill;
    //border: 1px solid var.$error__errorFill;
  }

  .cde-toast-title {
    color: var.$error__errorContent;
  }

  .cde-toast-message {
    color: var.$error__errorContent;
  }
}
